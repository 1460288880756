<template>
  <mf-card-container title="Flags da conta" description="Listagem das flags vinculadas a conta">
    <mf-loading-dialog :loading="$apollo.loading">
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="px-0">
            <general-flags :flags.sync="flags" />
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="auto">
            <mf-button :loading="loadingSave" label="Salvar" @click="saveFlags"></mf-button>
          </v-col>
        </v-row>
      </v-container>
    </mf-loading-dialog>
  </mf-card-container>
</template>

<script>
import { MUTATION_UPDATE_FLAGS, QUERY_GET_ACCOUNT } from '@/modules/accounts/graphql'
import GeneralFlags from './GeneralFlags.vue'
export default {
  components: {
    GeneralFlags
  },
  data: () => ({
    flags: {
      tribanco: false,
      force_all_campaigns_update: false,
      use_measure_units_on_sales: false,
      use_catalog: false,
      use_products_images: false,
      use_products_information: false,
      use_categories: false,
      import_sales_with_specific_products: false,
      ignore_imported_sales_on_pos: false,
      ignore_images_on_sales: false,
      use_realtime_cashback: false,
      use_product_scores_sorting: false,
      use_user_product_scores_sorting: false,
      ecommerce: {
        active: false
      },
      app_custom_printscreen: false,
      cbm: {
        active: false
      },
      industry: {
        has_mercapromo: false,
        only_mercapromo: false
      },
      products_wrong_bases: false
    },
    loadingSave: false
  }),
  apollo: {
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.$route.params.id }
      },
      update({ adminAccount }) {
        this.setValues(adminAccount)
      }
    }
  },
  methods: {
    async saveFlags() {
      const confirmed = await this.$confirm({
        title: 'Confirmar ação',
        message: 'Tem certeza que deseja realizar as alterações destes campos?',
        confirmText: 'Salvar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmed) return

      try {
        this.loadingSave = true
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_FLAGS,
          variables: {
            id: this.$route.params.id,
            tribanco: this.flags.tribanco,
            use_measure_units_on_sales: this.flags.use_measure_units_on_sales,
            use_catalog: this.flags.use_catalog,
            use_products_images: this.flags.use_products_images,
            use_products_information: this.flags.use_products_information,
            use_categories: this.flags.use_categories,
            import_sales_with_specific_products: this.flags.import_sales_with_specific_products,
            ignore_imported_sales_on_pos: this.flags.ignore_imported_sales_on_pos,
            ignore_images_on_sales: this.flags.ignore_images_on_sales,
            use_realtime_cashback: this.flags.use_realtime_cashback,
            use_product_scores_sorting: this.flags.use_product_scores_sorting,
            use_user_product_scores_sorting: this.flags.use_user_product_scores_sorting,
            force_all_campaigns_update: this.flags.force_all_campaigns_update,
            ecommerce_active: this.flags.ecommerce.active,
            app_custom_printscreen: this.flags.app_custom_printscreen,
            cbm_active: this.flags.cbm.active,
            has_mercapromo: this.flags.industry.has_mercapromo,
            only_mercapromo: this.flags.industry.has_mercapromo && this.flags.industry.only_mercapromo,
            products_wrong_bases: this.flags.products_wrong_bases
          }
        })
        this.$alert({
          alert_message: 'Flags atualizadas com sucesso',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (error) {
        console.log(error)
        this.$alert({
          alert_message: 'Erro ao atualizar flags',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.loadingSave = false
    },
    setValues(account) {
      this.flags.tribanco = account?.tribanco
      this.flags.force_all_campaigns_update = account?.summaries?.force_all_campaigns_update
      this.flags.use_measure_units_on_sales = account?.flags?.products?.use_measure_units_on_sales
      this.flags.use_catalog = account?.flags?.catalog_global?.use
      this.flags.use_products_images = account?.flags?.catalog_global?.use_products_images
      this.flags.use_products_information = account?.flags?.catalog_global?.use_products_information
      this.flags.use_categories = account?.flags?.catalog_global?.use_categories
      this.flags.import_sales_with_specific_products = account?.flags?.sales?.import_sales_with_specific_products
      this.flags.ignore_imported_sales_on_pos = account?.flags?.sales?.ignore_imported_sales_on_pos
      this.flags.ignore_images_on_sales = account?.flags?.sales?.ignore_images_on_sales
      this.flags.use_realtime_cashback = account?.flags?.sales?.use_realtime_cashback
      this.flags.use_product_scores_sorting = account?.flags?.app_sales?.use_product_scores_sorting
      this.flags.use_user_product_scores_sorting = account?.flags?.app_sales?.use_user_product_scores_sorting
      this.flags.ecommerce.active = account?.flags?.ecommerce?.active
      this.flags.app_custom_printscreen = account?.flags?.app_custom_printscreen
      this.flags.cbm.active = account?.flags?.cbm?.active
      this.flags.industry.has_mercapromo = account?.flags?.industry?.has_mercapromo
      this.flags.industry.only_mercapromo = account?.flags?.industry?.only_mercapromo
      this.flags.products_wrong_bases = account?.flags?.products?.wrong_bases
    }
  }
}
</script>
<style></style>
